import camelCase from "camelcase";

export function setFavicon(faviconUrl: string | null) {
  if (faviconUrl) {
    const link: HTMLLinkElement = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.rel = "shortcut icon";
    link.href = new URL(faviconUrl).href;
    document.getElementsByTagName("head")[0].appendChild(link);
  }
}

export function setTitle(title: string | null, titleTranslations: Record<string, string | null>, locale: string) {
  const camelizedLocale = camelCase(locale);
  const translatedTitle = titleTranslations && titleTranslations[camelizedLocale];

  if (translatedTitle) {
    document.title = translatedTitle;
  } else if (title) {
    document.title = title;
  } else {
    document.title = "PostCo: Returns Center";
  }
}
